import React from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { NavLink } from 'ion-navchevron'
import logo from '../../static/general/house-and-garden.svg'
import logoM from '../../static/general/house-and-garden-white.svg'
import logoMH from '../../static/general/h-white.svg'
import { Articles } from 'ion-article-cmp'
import NoImage from '../../static/no-image.png'
import { ImageOrNoImage } from 'ion-image'
import { Button } from 'semantic-ui-react'
import { MenuIco } from 'ion-icon'
import { defaultWidth, Mobile, DesktopTablet } from 'ion-media'
import useHasMounted from '../../../lib/useHasMounted'

const MenuTab = (props) => (
  <Articles
    section={props.section} pageSize={4} component={({ articles, noImage }) => {
      return articles.map((article) => (
        <article key={article.contentKey}>
          <Link to={'/' + article.getCanonicalUri()}>
            <ImageOrNoImage shape='square' width={320} image={article.image} alt={article.headline} noImage={noImage} />
            <h3>{article.headline}</h3>
          </Link>
        </article>
      ))
    }} noImage={NoImage} notFound={() => (<div>Not Found</div>)} {...props}
  />
)

const Header = (props) => {
  const hasMounted = useHasMounted()
  return (
    <header role='banner' className={props.useSmall ? 'reduced-header' : ''}>
      <a href='#main-content' className='show-on-focus' tabIndex='0'>
        Skip to content
      </a>
      <div className='wrapper'>
        <Mobile values={{ deviceWidth: defaultWidth }}>
          <Button tabIndex='2' className='menu-btn' onClick={props.handleToggleMenu}><MenuIco width='24' height='24' /></Button>
        </Mobile>
        <Link to='/' className='logo'>
          <Mobile values={{ deviceWidth: defaultWidth }}>
            <img src={logoM} className='logo-mob' alt='Cond&eacute; Nast House &amp; Garden' />
            <img src={logoMH} className='logo-mob-h' alt='Cond&eacute; Nast House &amp; Garden' />
          </Mobile>
          {hasMounted &&
            <DesktopTablet values={{ deviceWidth: defaultWidth }}>
              <img src={logo} alt='Cond&eacute; Nast House &amp; Garden' />
            </DesktopTablet>}
        </Link>
      </div>
      <DesktopTablet values={{ deviceWidth: defaultWidth }}>
        <div className='wrapper'>
          <nav>
            <ul>
              <li>
                <NavLink to='/design'>Design</NavLink>
                <ul className='menu-articles'>
                  <li>
                    <Link to='/design/trends'>Trends</Link>
                    <MenuTab section='design/trends' {...props} />
                  </li>
                  <li>
                    <Link to='/design/art'>Art</Link>
                    <MenuTab section='design/art' {...props} />
                  </li>
                  <li>
                    <Link to='/design/architecture'>Architecture</Link>
                    <MenuTab section='design/architecture' {...props} />
                  </li>
                  <li>
                    <Link to='/design/innovative'>Innovative Designs</Link>
                    <MenuTab section='design/innovative' {...props} />
                  </li>
                </ul>
              </li>
              <li>
                <NavLink to='/interiors'>Interiors</NavLink>
                <ul className='menu-articles'>
                  <li>
                    <Link to='/interiors/bathroom'>Bathroom</Link>
                    <MenuTab section='interiors/bathroom' {...props} />
                  </li>
                  <li>
                    <Link to='/interiors/bedroom'>Bedroom</Link>
                    <MenuTab section='interiors/bedroom' {...props} />
                  </li>
                  <li>
                    <Link to='/interiors/kitchen'>Kitchen</Link>
                    <MenuTab section='interiors/kitchen' {...props} />
                  </li>
                  <li>
                    <Link to='/interiors/living-room'>Living Room</Link>
                    <MenuTab section='interiors/living-room' {...props} />
                  </li>
                </ul>
              </li>
              <li>
                <NavLink to='/gardens'>Gardens</NavLink>
                <ul className='menu-articles'>
                  <li>
                    <Link to='/gardens'>Gardens</Link>
                    <MenuTab section='gardens' {...props} />
                  </li>
                </ul>
              </li>
              <li>
                <NavLink to='/travel-leisure'>Travel &amp; Leisure</NavLink>
                <ul className='menu-articles'>
                  <li>
                    <Link to='/travel-leisure'>Travel &amp; Leisure</Link>
                    <MenuTab section='travel-leisure' {...props} />
                  </li>
                </ul>
              </li>
              <li>
                <NavLink to='/recipes'>Recipes</NavLink>
                <ul className='menu-articles'>
                  <li>
                    <Link to='/recipes'>Recipes</Link>
                    <MenuTab section='recipes' {...props} />
                  </li>
                </ul>
              </li>
              <li>
                <NavLink to='/diy'>DIY</NavLink>
                <ul className='menu-articles'>
                  <li>
                    <Link to='/diy'>DIY</Link>
                    <MenuTab section='diy' {...props} />
                  </li>
                </ul>
              </li>
              <li>
                <NavLink to='/video'>Video</NavLink>
                <ul className='menu-articles'>
                  <li>
                    <Link to='/video'>Video</Link>
                    <MenuTab section='video' {...props} />
                  </li>
                </ul>
              </li>
              <li>
                <NavLink to='/shopping'>Shopping</NavLink>
                <ul className='menu-articles'>
                  <li>
                    <Link to='/shopping'>Shopping</Link>
                    <MenuTab section='shopping' {...props} />
                  </li>
                  <li>
                    <Link to='/shopping/gift-guide'>Gift Guide</Link>
                    <MenuTab section='shopping/gift-guide' {...props} />
                  </li>
                </ul>
              </li>
              <li>
                <NavLink to='/win'>Win</NavLink>
                <ul className='menu-articles'>
                  <li>
                    <Link to='/win'>Win</Link>
                    <MenuTab section='win' {...props} />
                  </li>
                </ul>
              </li>
              {/* <li>
                <NavLink to='/design-directory'>Design Directory</NavLink>
              </li> */}
            </ul>
          </nav>
        </div>
      </DesktopTablet>
    </header>
  )
}
export default connect((state) => ({ articles: state.articles }))(Header)
