'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
var Outbrain = function Outbrain(events) {
  if (typeof window === 'undefined' || !process.env.RAZZLE_ENABLE_OUTBRAIN) {
    return;
  }
  if (typeof window.OBR === 'undefined') {
    // throw new Error(
    console.error('window.OBR is not defined, Have you forgotten to include Outbrain?');
  } else {
    events.forEach(function (event) {
      switch (event.event) {
        case 'ArticlePageview':
          console.log('reloadOutbrain', event);
          window.OBR.extern.reloadWidget();
          break;
        default:
          break;
      }
    });
  }
};

exports.default = Outbrain;