import { all } from 'redux-saga/effects'
import { watchFetchArticles } from './articles'
import { watchFetchMostReadArticles } from './mostReadArticles'
import { watchFetchArticleContent, watchFetchArticle, watchPreviewArticle, watchSubmitCompetition } from './article'
import { watchNavChanges } from './app'
import { watchFetchStaticPage } from './staticPage'
import { watchFetchMagazineCover } from './magazineCover'
import { watchFeedback } from './feedback'
import { watchSearchArticles, watchSearchArticlesMore } from './search'
import { watchSubscribe } from './subscribe'
import { watchDesignDirectory } from './designDirectory'
import { watchFetchSubscribeBlock } from './subscribeBlock'
import { watchFetchAuthor } from './author'
import { watchFetchAuthors } from './authors'

export default function * rootSaga () {
  try {
    yield all([
      watchFetchArticleContent(),
      watchFetchArticle(),
      watchSubmitCompetition(),
      watchPreviewArticle(),
      watchFetchArticles(),
      watchFetchMostReadArticles(),
      watchNavChanges(),
      watchFeedback(),
      watchDesignDirectory(),
      watchFetchStaticPage(),
      watchSubscribe(),
      watchSearchArticles(),
      watchSearchArticlesMore(),
      watchFetchMagazineCover(),
      watchFetchSubscribeBlock(),
      watchFetchAuthor(),
      watchFetchAuthors()
    ])
  } catch (e) {
    console.error('ERROR Saga ERROR', e)
    throw e
  }
}
