import axios from 'axios'
import { call, put, takeLatest } from 'redux-saga/effects'

export const FETCH_AUTHOR = 'api/FETCH_AUTHOR'
export const FETCH_AUTHOR_SUCCESS = 'api/FETCH_AUTHOR_SUCCESS'
export const FETCH_AUTHOR_ERROR = 'api/FETCH_AUTHOR_ERROR'
export const FETCH_AUTHOR_NOT_FOUND = 'api/FETCH_AUTHOR_NOT_FOUND'

const TRANSLATOR_URL = typeof window !== 'undefined' ? '/data/translator' : process.env.RAZZLE_TRANSLATOR

export async function fetchAuthorAPI (authorSlug) {
  const key = '/author/' + process.env.RAZZLE_TITLE_KEY + '/' + authorSlug
  try {
    const response = await axios.get(TRANSLATOR_URL + key)
    return response.data
  } catch (error) {
    console.log('fetchAuthorAPI:', error.message)
    throw error
  }
}

function * fetchAuthorSaga ({ authorSlug }) {
  try {
    const res = yield call(fetchAuthorAPI, authorSlug)
    yield put({ type: FETCH_AUTHOR_SUCCESS, payload: res })
  } catch (e) {
    yield put({ type: FETCH_AUTHOR_ERROR, payload: e.message })
  }
}

export function * watchFetchAuthor () {
  yield takeLatest(FETCH_AUTHOR, fetchAuthorSaga)
}

export const fetchAuthor = (authorSlug) => ({ type: FETCH_AUTHOR, isFetching: true, hasFetched: false, authorSlug })

export const initialState = {
  didInvalidate: false,
  lastFetch: 0,
  isFetching: false,
  hasFetched: false,
  hasError: false,
  is404: false,
  error: null,
  author: null
}

export const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_AUTHOR:
      return Object.assign({}, state, {
        didInvalidate: false,
        isFetching: true,
        hasFetched: false,
        is404: false,
        hasError: false,
        error: null
      })
    case FETCH_AUTHOR_NOT_FOUND:
      return Object.assign({}, state, {
        hasError: true,
        is404: true,
        hasFetched: true,
        isFetching: false,
        didInvalidate: false,
        author: null
      })
    case FETCH_AUTHOR_ERROR:
      return Object.assign({}, state, {
        hasError: true,
        is404: false,
        error: payload,
        hasFetched: true,
        isFetching: false,
        didInvalidate: true
      })
    case FETCH_AUTHOR_SUCCESS:
      return Object.assign({}, state, {
        lastFetch: new Date(),
        hasFetched: true,
        isFetching: false,
        didInvalidate: false,
        author: payload,
        hasError: false,
        is404: false,
        error: null
      })
    default:
      return state
  }
}
