import React from 'react'
import { NavLink } from 'ion-navchevron'
import { Link } from 'react-router-dom'

const NavMenu = (props) => {
  const LinkComponent = props.amp ? Link : NavLink
  return (
    <ul className='main-nav'>
      <li>
        <LinkComponent to='/design'>Design</LinkComponent>
        <ul className='sub-nav'>
          <li>
            <LinkComponent to='/design/trends'>Trends</LinkComponent>
          </li>
          <li>
            <LinkComponent to='/design/art'>Art</LinkComponent>
          </li>
          <li>
            <LinkComponent to='/design/architecture'>Architecture</LinkComponent>
          </li>
          <li>
            <LinkComponent to='/design/innovative'>Innovative Designs</LinkComponent>
          </li>
        </ul>
      </li>
      <li>
        <LinkComponent to='/interiors'>Interiors</LinkComponent>
        <ul className='sub-nav'>
          <li>
            <LinkComponent to='/interiors/bathroom'>Bathroom</LinkComponent>
          </li>
          <li>
            <LinkComponent to='/interiors/bedroom'>Bedroom</LinkComponent>
          </li>
          <li>
            <LinkComponent to='/interiors/kitchen'>Kitchen</LinkComponent>
          </li>
          <li>
            <LinkComponent to='/interiors/living-room'>Living Room</LinkComponent>
          </li>
        </ul>
      </li>
      <li>
        <LinkComponent to='/gardens'>Gardens</LinkComponent>
      </li>
      <li>
        <LinkComponent to='/travel-leisure'>Travel &amp; Leisure</LinkComponent>
      </li>
      <li>
        <LinkComponent to='/recipes'>Recipes</LinkComponent>
      </li>
      <li>
        <LinkComponent to='/diy'>Do It Yourself</LinkComponent>
      </li>
      <li>
        <LinkComponent to='/video'>Video</LinkComponent>
      </li>
      <li>
        <LinkComponent to='/shopping'>Shopping</LinkComponent>
        <ul className='sub-nav'>
          <li>
            <LinkComponent to='/shopping/gift-guide'>Gift Guide</LinkComponent>
          </li>
        </ul>
      </li>
      <li>
        <LinkComponent to='/win'>Win</LinkComponent>
      </li>
      {/* <li>
        <LinkComponent to='/design-directory'>Design Directory</LinkComponent>
      </li> */}
    </ul>
  )
}

export default NavMenu
