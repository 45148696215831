import { combineReducers } from 'redux'
import { Reducer as articles } from './articles'
import { Reducer as mostReadArticles } from './mostReadArticles'
import { Reducer as article } from './article'
import { Reducer as navPath } from 'ion-navchevron'
import { Reducer as app } from './app'
import { Reducer as staticPage } from './staticPage'
import { Reducer as feedback } from './feedback'
import { Reducer as designDirectory } from './designDirectory'
import { Reducer as subscribe } from './subscribe'
import { Reducer as search } from './search'
import { Reducer as magazineCover } from './magazineCover'
import { Reducer as user } from './user'
import { Reducer as subscribeBlock } from './subscribeBlock'
import { Reducer as author } from './author'
import { Reducer as authors } from './authors'

export default combineReducers({
  article,
  articles,
  mostReadArticles,
  navPath,
  app,
  staticPage,
  feedback,
  designDirectory,
  subscribe,
  search,
  magazineCover,
  user,
  subscribeBlock,
  author,
  authors
})
