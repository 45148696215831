
import { takeLatest, put } from 'redux-saga/effects'
import { NAVIGATE_TO } from 'ion-navchevron'

export const HIDE_MENU = 'HIDE_MENU'
export const SHOW_MENU = 'SHOW_MENU'
export const TOGGLE_MENU = 'TOGGLE_MENU'

export const HIDE_SEARCH = 'HIDE_SEARCH'
export const SHOW_SEARCH = 'SHOW_SEARCH'
export const TOGGLE_SEARCH = 'TOGGLE_SEARCH'

export const PAGE_VIEW = 'PAGE_VIEW'
export const SECTION_PAGE_VIEW = 'SECTION_PAGE_VIEW'
export const UPDATE_CONNECTIVITY = 'UPDATE_CONNECTIVITY'

export const UPDATE_WINGBANNER_LEFT_ACTIVE = 'UPDATE_WINGBANNER_LEFT_ACTIVE'
export const UPDATE_WINGBANNER_RIGHT_ACTIVE = 'UPDATE_WINGBANNER_RIGHT_ACTIVE'

export const SET_SUBSCRIPTION = 'SET_SUBSCRIPTION'

export const PREFETCH = 'PREFETCH'

export const hideMenu = () => ({ type: HIDE_MENU, payload: false })
export const showMenu = () => ({ type: SHOW_MENU, payload: true })
export const handleToggleMenu = () => ({ type: TOGGLE_MENU })

export const hideSearch = () => ({ type: HIDE_SEARCH, payload: false })
export const showSearch = () => ({ type: SHOW_SEARCH, payload: true })
export const toggleSearch = () => ({ type: TOGGLE_SEARCH })

export const updateConnectivity = (isConnected) => ({ type: UPDATE_CONNECTIVITY, payload: isConnected })

export const pageView = (path) => ({ type: PAGE_VIEW, payload: path })
export const sectionPageView = (path) => ({ type: SECTION_PAGE_VIEW, payload: path })
export const isConnected = state => state.app.isConnected

export const setSubscription = (subscription) => ({ type: SET_SUBSCRIPTION, payload: subscription })

export const Prefetch = () => ({ type: PREFETCH })

function * hideMenuSaga () {
  yield put({ type: HIDE_MENU, payload: false })
}

export function * watchNavChanges () {
  yield takeLatest(NAVIGATE_TO, hideMenuSaga)
}

const defaultState = {
  showMenu: false,
  showSearch: false,
  isConnected: true,
  path: '',
  wingbannerLeftActive: false,
  wingbannerRightActive: false
}

export const Reducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case SET_SUBSCRIPTION:
      return { ...state, ...{ subscription: (payload === false ? false : JSON.stringify(payload)) } }
    case UPDATE_CONNECTIVITY:
      return { ...state, ...{ isConnected: payload } }
    case SECTION_PAGE_VIEW:
    case PAGE_VIEW:
      return { ...state, ...{ path: payload } }
    case HIDE_SEARCH:
    case SHOW_SEARCH:
      return { ...state, ...{ showSearch: payload } }
    case TOGGLE_SEARCH:
      return { ...state, ...{ showSearch: !state.showSearch } }
    case SHOW_MENU:
    case HIDE_MENU:
      return { ...state, ...{ showMenu: payload } }
    case TOGGLE_MENU:
      return { ...state, ...{ showMenu: !state.showMenu } }
    default:
      return state
  }
}
