import React, { useCallback, useState } from 'react'
import Popup from 'ion-popup'
import CloseBtn from '../static/icons/close-dk.svg'
import { useCookie, isBrowser } from './ui/utils'

const CookieForm = (props) => {
  const [consent, setConsent] = useCookie('Cookie-Consent', 'none')
  const [closed, setIsClosed] = useState(false)
  const handleConsent = useCallback(() => {
    setConsent('accepted', { days: 365 })
  })
  if (isBrowser && consent === 'none' && !closed) {
    return (
      <Popup visible overlay={false} placement='bottom-right' className='cookie-policy'>
        <div className='close'><img src={CloseBtn} width={24} height={24} onClick={() => setIsClosed(true)} /></div>
        <p>This website uses cookies to ensure you get the best experience on our website. <a href='/privacy-policy'>Learn more</a></p>
        <button onClick={handleConsent}>Accept</button>
      </Popup>
    )
  } else {
    return ''
  }
}

export default CookieForm
