import { ImageOrNoImage } from 'ion-image'
import NoImage from '../static/no-image.png'
import { DesktopTablet } from 'ion-media'
import { Search, LinkedIn, Facebook, Twitter, Instagram, Pinterest, Youtube, TikTok } from 'ion-icon'
import MagazineCover from '../components/MagazineCover'
import ArticleSocialShare from '../components/ArticleSocialShare'

export const ArticleCardSkeleton = (props) => {
  return (
    <article className='skeleton'>
      <div className='article-list-img'>
        <ImageOrNoImage shape='square' width={400} noImage={NoImage} />
      </div>
      <div className='content'>
        {/* Article title */}
        <div className='title' />
        <div className='title' />
        <div className='title title-short' />
        <div className='p' />
        <div className='p p-short' />
      </div>
    </article>
  )
}

export function SidebarSkeleton (props) {
  return (
    <aside className='sidebar' id='sidebar'>
      <DesktopTablet>
        <div className='follow-us'>
          <span>Follow us</span>
          <nav>
            <ul>
              <li>
                <a href='https://www.linkedin.com/in/house-and-garden-sa-cond%C3%A9-nast-401839242/' target='_blank' rel='noopener noreferrer' title='Follow House and Garden on LinkedIn'>
                  <LinkedIn width='18' height='18' />
                </a>
              </li>
              <li>
                <a href='https://www.facebook.com/HouseGardenSA/' target='_blank' rel='noopener noreferrer' title='Like House and Garden on Facebook'>
                  <Facebook width='18' height='18' />
                </a>
              </li>
              <li>
                <a href='https://www.instagram.com/houseandgardensa/' target='_blank' rel='noopener noreferrer' title='Follow House and Garden on Instagram'>
                  <Instagram width='18' height='18' />
                </a>
              </li>
              <li>
                <a href='https://za.pinterest.com/housegardensa/' target='_blank' rel='noopener noreferrer' title='Follow House and Garden on Pinterest'>
                  <Pinterest width='18' height='18' />
                </a>
              </li>
              <li>
                <a href='https://twitter.com/HouseGardenSA' target='_blank' rel='noopener noreferrer' title='Follow House and Garden on Twitter'>
                  <Twitter width='18' height='18' fill='white' />
                </a>
              </li>
              <li>
                <a href='https://www.youtube.com/channel/UC17OWNqdopaDxqz0vOCwhFg' target='_blank' rel='noopener noreferrer' title='Subscribe to House and Garden on Youtube'>
                  <Youtube width='18' height='18' />
                </a>
              </li>
              <li>
                <a href='https://www.tiktok.com/@houseandgardensa' className='social-btn' target='_blank' rel='noopener noreferrer'>
                  <TikTok width='18' height='18' />
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </DesktopTablet>
      <div className='sidebar-search'>
        <form action='/search' method='get'>
          <input type='search' placeholder='Search House & Garden' name='q' id='q' />
          <button><Search /></button>
        </form>
      </div>
      {props.children}
      <div className='newsletter-subscribe'>
        <h5>Sign up to your weekly dose of inspiration</h5>
        <form name='item-form' id='item-form'>
          <input type='text' name='firstName' placeholder='First Name' id='firstName' required />
          <input type='text' name='lastName' placeholder='Last Name' id='lastName' />
          <input type='email' name='email' placeholder='Email Address' id='email' required />
          <button type='submit'>Subscribe</button>
        </form>
      </div>
      <div />
      <div className='sidebar-articles'>
        {/* <MostReadArticles section={props.section} className='side-articles' noImage={NoImage} contentKey={this.props.contentKey} /> */}
      </div>
    </aside>
  )
}

export const ArticlePageSkeleton = () => (
  <div className='wrapper'>
    <div className='article-page'>
      <article role='contentinfo' aria-label='article' className='skeleton'>
        <h1 className='title' />
        <p className='meta' style={{ marginRight: '20%' }}>&nbsp;</p>
        <p className='meta' style={{ marginRight: '60%' }}>&nbsp;</p>
        <ArticleSocialShare className='inline-share-buttons' />
        <div itemProp='articleBody' className='article-body'>
          <div className='figure' width={640} height={480}>&nbsp;</div>
          <div className='articleBodyMore' id='article-more-body'>
            <p>
              <div>&nbsp;</div>
              <div>&nbsp;</div>
              <div>&nbsp;</div>
            </p>
            <p>
              <div>&nbsp;</div>
              <div>&nbsp;</div>
              <div>&nbsp;</div>
            </p>
            <p>
              <div>&nbsp;</div>
              <div>&nbsp;</div>
              <div>&nbsp;</div>
            </p>
            <p>
              <div>&nbsp;</div>
              <div>&nbsp;</div>
              <div>&nbsp;</div>
            </p>
          </div>
        </div>
      </article>
      <SidebarSkeleton>
        <MagazineCover />
      </SidebarSkeleton>
    </div>
  </div>
)
export const DefaultSectionPageSkeleton = (props) => {
  return (
    <div className='wrapper article-listing'>
      <div className='article-list'>
        <DefaultTemplateSkeleton />
      </div>
      <SidebarSkeleton>
        <DesktopTablet>
          <MagazineCover />
        </DesktopTablet>
      </SidebarSkeleton>
    </div>
  )
}

export const DefaultTemplateSkeleton = (props) => {
  return (
    <>
      <div className='skeleton'>
        <h1>{props.sectionLabel}</h1>
      </div>
      <ArticleCardSkeleton />
      <ArticleCardSkeleton />
      <ArticleCardSkeleton />
      <ArticleCardSkeleton />
      <ArticleCardSkeleton />
      <ArticleCardSkeleton />
    </>
  )
}
