import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'
import logo from '../../static/general/house-and-garden-footer.svg'
import iab from '../../static/general/iab.gif'

const Footer = (props) => (
  <>
    <footer role='contentinfo'>
      <div className='wrapper'>
        <nav>
          <ul>
            <li><Link to='/subscriptions'>Subscriptions</Link></li>
            <li><Link to='/my-notifications'>Notifications</Link></li>
            <li><Link to='/my-bookmarks'>Bookmarks</Link></li>
            <li><Link to='/advertise-with-us'>Advertise with us</Link></li>
            <li><Link to='/contact-us'>Contact Us</Link></li>
            <li><Link to='/terms-and-conditions'>Terms and Conditions</Link></li>
            <li><Link to='/privacy-policy'>Privacy Policy</Link></li>
            {/* <li><Link to='/design-directory'>Design Directory</Link></li> */}
          </ul>
        </nav>
        <img src={logo} alt='Cond&eacute; Nast House &amp; Garden' />
        <p>&copy; {new Date().toLocaleDateString('en-ZA', { year: 'numeric' })} Cond&eacute; Nast. All rights reserved. House &amp; Garden&reg; is a registered trademark of Cond&eacute; Nast New Markets Europe/Africa, Inc. used under license by CONTENT NATION MEDIA (PTY) ltd. Material on this site may not be reproduced, distributed, transmitted, cached, otherwise used, except with prior written permission of the copyright owner.</p>
        <p>House and Garden UK <a href='https://www.houseandgarden.co.uk/' target='_blank' rel='noopener noreferrer'>www.houseandgarden.co.uk</a></p>
        <img src={iab} alt='iab South Africa' />
      </div>
    </footer>
  </>
)

export default Footer
