import axios from 'axios'
import { call, put, takeLatest } from 'redux-saga/effects'
import { fetchArticlesAPI } from './articles'

export const FETCH_CRAFTPAGE = 'api/FETCH_CRAFTPAGE'
export const FETCH_CRAFTPAGE_SUCCESS = 'api/FETCH_CRAFTPAGE_SUCCESS'
export const FETCH_CRAFTPAGE_ERROR = 'api/FETCH_CRAFTPAGE_ERROR'

const SERVER_URL = typeof window !== 'undefined' ? '' : 'http://localhost:' + process.env.PORT

const fetchStaticPageAPI = (pageName) => axios.get(SERVER_URL + '/data/static/' + pageName)
  .then(response => response.data)
  .catch(err => {
    // console.error(err)
    throw err
  })

function * fetchStaticPageSaga ({ pageName }) {
  try {
    const res = yield call(fetchStaticPageAPI, pageName)
    let latestArticles = []
    try {
      latestArticles = yield call(fetchArticlesAPI, 'all-content-feed', 0, 9)
    } catch (e) {
      console.log('Unable to fetch latestArticles')
    }
    yield put({ type: FETCH_CRAFTPAGE_SUCCESS, payload: res, latestArticles: latestArticles.contents })
  } catch (e) {
    yield put({ type: FETCH_CRAFTPAGE_ERROR, payload: e.message })
  }
}

export function * watchFetchStaticPage () {
  yield takeLatest(FETCH_CRAFTPAGE, fetchStaticPageSaga)
}

// Saga actions
export const fetchStaticPage = (pageName) => ({ type: FETCH_CRAFTPAGE, isFetching: true, hasFetched: false, pageName })

export const initialState = {
  lastFetch: 0,
  didInvalidate: false,
  isFetching: false,
  hasFetched: false,
  hasError: false,
  canonical: null,
  error: null,
  latestArticles: null,
  staticPage: null // do not mutate these
}

export const Reducer = (state = initialState, { type, payload, latestArticles, meta }) => {
  switch (type) {
    case FETCH_CRAFTPAGE:
      return Object.assign({}, state, {
        didInvalidate: false,
        isFetching: true,
        hasFetched: false,
        hasError: false,
        canonical: null,
        error: null
      })
    case FETCH_CRAFTPAGE_ERROR:
      return Object.assign({}, state, {
        hasError: true,
        error: payload,
        hasFetched: true,
        isFetching: false,
        didInvalidate: false,
        canonical: null
      })
    case FETCH_CRAFTPAGE_SUCCESS:
      return Object.assign({}, state, {
        lastFetch: new Date(),
        hasFetched: true,
        isFetching: false,
        didInvalidate: false,
        staticPage: payload,
        latestArticles,
        hasError: false,
        canonical: null,
        error: null
      })
    default:
      return state
  }
}
