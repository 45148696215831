'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

// To prevent double measuring due to the script load, suppress the first call 
var firstCall = true;

var TikTok = function TikTok(events) {
  if (typeof window === 'undefined') {
    return;
  }
  if (firstCall) {
    firstCall = false;
    return;
  }
  if (typeof window.ttq === 'undefined') {
    console.error('window.ttq is not defined, Have you forgotten to include TikTok?');
  } else {
    events.forEach(function (event) {
      switch (event.event) {
        case 'VirtualPageview':
          if (!event.virtualPageURL.match('[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}')) {
            window.ttq.track('ViewContent', {
              contents: [{
                content_type: 'article',
                content_id: event.contentKey,
                path: event.virtualPageURL
              }]
            });
          }
          break;
        case 'ArticlePageview':
          window.ttq.track('ViewContent', {
            contents: [{
              content_type: 'article',
              content_id: event.contentKey,
              path: event.virtualPageURL
            }]
          });
          break;
        default:
          break;
      }
    });
  }
};

exports.default = TikTok;