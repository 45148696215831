import axios from 'axios'
import { call, put, takeEvery } from 'redux-saga/effects'

export const SUBSCRIBE = 'api/SUBSCRIBE'
export const SUBSCRIBE_SUCCESS = 'api/SUBSCRIBE_SUCCESS'
export const SUBSCRIBE_ERROR = 'api/SUBSCRIBE_ERROR'
export const REINITIALIZE = 'api/REINITIALIZE'
export const UPDATE_DATA = 'api/UPDATE_DATA'

const SERVER_URL = typeof window !== 'undefined' ? '' : 'http://localhost:' + process.env.PORT

const subscribeAPI = (data) => {
  return axios.post(SERVER_URL + '/data/newsletter/condenast/subscribe', {
    contact_name: data.payload.firstName,
    contact_lastname: data.payload.lastName,
    email: data.payload.email,
    lists: [3954],
    hp: '',
    error: ''
  })
    .then(response => response.data)
    .catch(err => {
      // console.error(err)
      throw err
    })
}

function * subscribeSaga (data) {
  try {
    yield call(subscribeAPI, data)
    yield put({ type: SUBSCRIBE_SUCCESS })
  } catch (e) {
    console.error('ERROR while subscribing', e.message)
    yield put({ type: SUBSCRIBE_ERROR, payload: { message: e.message } })
  }
}

export function * watchSubscribe () {
  yield takeEvery(SUBSCRIBE, subscribeSaga)
}

// Saga actions
export const subscribeNewsletter = (data) => ({ type: SUBSCRIBE, payload: data })
export const updateForm = (data) => ({ type: UPDATE_DATA, payload: data })
export const reinitialize = () => ({ type: REINITIALIZE, payload: {} })

const defaultState = {
  firstName: '',
  lastName: '',
  email: '',
  lists: [],
  errorMessage: '',
  captchaVerified: false
}

export const Reducer = (state = defaultState, { type, payload }) => {
  switch (type) {
    case REINITIALIZE:
      return Object.assign({}, defaultState)
    case UPDATE_DATA:
      return Object.assign({}, state, payload)
    case SUBSCRIBE:
      return Object.assign({}, state, {
        didInvalidate: false,
        isSubmitting: true,
        hasSubmitted: false,
        hasError: false,
        error: null,
        data: payload
      })
    case SUBSCRIBE_ERROR:
      return Object.assign({}, state, {
        hasError: true,
        error: payload.message,
        isSubmitting: false,
        hasSubmitted: false,
        didInvalidate: true
      })
    case SUBSCRIBE_SUCCESS:
      return Object.assign({}, state, {
        isSubmitting: false,
        hasSubmitted: true,
        didInvalidate: false
      })
    default:
      return state
  }
}
