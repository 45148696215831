// import Recaptcha from 'react-google-recaptcha'
import React, { Component, Fragment } from 'react'
import Helmet from 'react-helmet'
import { Dimmer, Loader, Segment } from 'semantic-ui-react/dist/commonjs'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { subscribeNewsletter, updateForm, reinitialize } from '../../store/subscribe'

export class Subscribe extends Component {
  constructor (props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.verifyCallback = this.verifyCallback.bind(this)
  }

  componentDidMount () {
    this.props.reinitialize()
  }

  handleChange (event) {
    const newState = {}
    newState[event.target.name] = event.target.value
    this.props.updateForm(newState)
  }

  handleCheckboxChange (event) {
    this.props.toggleNewsletterSubscription(event.target.value)
  }

  validateEmail (email) {
    const re = /^[^.@][^@]+@{1}[^@]+\.[^@]+[^.@]$/i
    return email.match(re)
  }

  validateName (name) {
    const re = /^[`a-zA-ZàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ' -]{2,45}$/i
    return name.match(re)
  }

  verifyCallback () {
    this.props.updateForm({ captchaVerified: true })
  }

  handleSubmit (event) {
    event.preventDefault()
    if (!this.validateEmail(this.props.email)) {
      this.props.updateForm({ output: 'Please enter a valid email address' })
      return false
    } else if (!this.validateName(this.props.firstName)) {
      this.props.updateForm({ output: 'Please enter a valid first name' })
      return false
    } else if (!this.validateName(this.props.lastName)) {
      this.props.updateForm({ output: 'Please enter a valid last name' })
      return false
    // } else if (!this.props.captchaVerified) {
    //   this.props.updateForm({ output: 'Please enter a Captcha to verify that you are not a robot' })
    //   return false
    }
    this.props.subscribeNewsletter({ email: this.props.email, firstName: this.props.firstName, lastName: this.props.lastName })
    return true
  }

  render () {
    const props = this.props
    const showForm = !props.hasSubmitted && !props.isSubmitting
    const submitting = props.isSubmitting
    const showThankYou = props.hasSubmitted
    return (
      <div className='wrapper static-page'>
        <div className='newsletter-subscribe'>
          <img src='../newsletter-banner.jpg' alt='Newsletter signup banner' />
          <p>Sign up to H&G's weekly newsletter for your daily source of design inspiration, news and profiles.</p>
          {showForm &&
            <>
              <form name='item-form' id='item-form' onSubmit={this.handleSubmit}>
                {props.error &&
                  <small style={{ color: 'red' }}>{this.props.error ? this.props.error : 'An error occured, please try again'}</small>}
                <label htmlFor='firstName'>Enter your first name</label>
                <input type='text' name='firstName' id='firstName' value={this.props.firstName} onChange={this.handleChange} required />
                <label htmlFor='lastName'>Enter your last name</label>
                <input type='text' name='lastName' id='lastName' value={this.props.lastName} onChange={this.handleChange} required />
                <label htmlFor='email'>Enter your e-mail address</label>
                <input type='email' name='email' id='email' value={this.props.email} onChange={this.handleChange} required />
                {/* <Recaptcha sitekey='6LfsLz4cAAAAALqO3FpVjEBCqOp-wBHVF9qJXuWn' onChange={this.verifyCallback} className='mt-20' /> */}
                <button type='submit'>Subscribe</button>
              </form>
            </>}
          {submitting &&
            <>
              <Helmet title='Article' />
              <Segment>
                <Dimmer active>
                  <Loader indeterminate size='large'>Submitting subscription.....</Loader>
                </Dimmer>
              </Segment>
            </>}
          {showThankYou &&
            <div className='thank-you'>
              <Helmet title='Article' />
              <p>Thank you for Subscribing!</p>
              <p>We have sent a confirmation email to the email address that you provided.<br />Click on the link in the email to confirm your subscription to our newsletter.</p>
            </div>}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => (Object.assign({}, state.subscribe))

const mapDispatchToProps = (dispatch) => bindActionCreators({
  subscribeNewsletter,
  updateForm,
  reinitialize
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Subscribe)
