import React from 'react'
import NavMenu from './NavMenu'
import { LinkedIn, Facebook, Twitter, Instagram, Close, Pinterest, Search, Youtube, Rss, TikTok } from 'ion-icon'

const MobileMenu = ({ onToggleMenu }) => (
  <nav>
    <button className='close-btn' onClick={onToggleMenu}><Close width='20' height='20' /></button>
    <ul className='menu-social'>
      <li>
        <a href={process.env.RAZZLE_SITE_LINKEDIN} target='_blank' rel='noopener noreferrer' title='Follow House and Garden on LinkedIn'>
          <LinkedIn width='26' height='26' />
        </a>
      </li>
      <li>
        <a href={process.env.RAZZLE_SITE_FACEBOOK} target='_blank' rel='noopener noreferrer' title='Like House and Garden on Facebook'>
          <Facebook width='26' height='26' />
        </a>
      </li>
      <li>
        <a href={process.env.RAZZLE_SITE_INSTAGRAM} target='_blank' rel='noopener noreferrer' title='Follow House and Garden on Instagram'>
          <Instagram width='26' height='26' />
        </a>
      </li>
      <li>
        <a href={process.env.RAZZLE_SITE_PINTEREST} target='_blank' rel='noopener noreferrer' title='Follow House and Garden on Pinterest'>
          <Pinterest width='26' height='26' />
        </a>
      </li>
      <li>
        <a href={process.env.RAZZLE_SITE_TWITTER} target='_blank' rel='noopener noreferrer' title='Follow House and Garden on Twitter'>
          <Twitter width='26' height='26' fill='white' />
        </a>
      </li>
      <li>
        <a href={process.env.RAZZLE_SITE_YOUTUBE} target='_blank' rel='noopener noreferrer' title='Subscribe to House and Garden on Youtube'>
          <Youtube width='26' height='26' />
        </a>
      </li>
      <li>
        <a href={process.env.RAZZLE_SITE_TIKTOK} target='_blank' rel='noopener noreferrer' title='Subscribe to House and Garden on TikTok'>
          <TikTok width='26' height='26' />
        </a>
      </li>
    </ul>
    <div className='mobile-nav-search'>
      <form action='/search' method='get'>
        <input type='search' placeholder='Search House & Garden' name='q' id='q' />
        <button><Search width='20' height='18' /></button>
      </form>
    </div>
    <NavMenu />
    <ul className='menu-social'>
      <li>
        <a href={process.env.RAZZLE_SITE_RSS} target='_blank' rel='noopener noreferrer' title='Subscribe to House and Gardens RSS Feed'>
          <Rss width='26' height='26' />
        </a>
      </li>
    </ul>
  </nav>
)

export default MobileMenu
