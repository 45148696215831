import axios from 'axios'
import { call, put, takeEvery } from 'redux-saga/effects'

export const FEEDBACK = 'api/FEEDBACK'
export const FEEDBACK_SUCCESS = 'api/FEEDBACK_SUCCESS'
export const FEEDBACK_ERROR = 'api/FEEDBACK_ERROR'
export const UPDATE_DATA = 'api/UPDATE_DATA'

const SERVER_URL = typeof window !== 'undefined' ? '' : 'http://localhost:' + process.env.PORT

const feedbackAPI = (data) => {
  return axios.post(SERVER_URL + '/data/feedback/submit', data)
    .then(response => response.data)
    .catch(err => {
      // console.error(err)
      throw err
    })
}

function * feedbackSaga ({ data }) {
  try {
    yield call(feedbackAPI, data)
    yield put({ type: FEEDBACK_SUCCESS })
  } catch (e) {
    console.error('ERROR while submitting', e.message)
    yield put({ type: FEEDBACK_ERROR, payload: { message: e.message } })
  }
}

export function * watchFeedback () {
  yield takeEvery(FEEDBACK, feedbackSaga)
}

// Saga actions
export const submitFeedback = (data) => ({ type: FEEDBACK, data })
export const updateForm = (data) => ({ type: UPDATE_DATA, payload: data })

export const initialState = {
  fullName: '',
  phone: '',
  email: '',
  enquiry: '',
  message: '',
  output: '',
  captchaVerified: false
}

export const Reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case UPDATE_DATA:
      return Object.assign({}, state, payload)
    case FEEDBACK:
      return Object.assign({}, state, {
        didInvalidate: false,
        isSubmitting: true,
        hasSubmitted: false,
        hasError: false,
        error: null,
        data: payload
      })
    case FEEDBACK_ERROR:
      return Object.assign({}, state, {
        hasError: true,
        error: payload.message,
        hasSubmitted: true,
        isSubmitting: false,
        didInvalidate: true
      })
    case FEEDBACK_SUCCESS:
      return Object.assign({}, state, {
        hasSubmitted: true,
        isSubmitting: false,
        didInvalidate: false
      })
    default:
      return state
  }
}
