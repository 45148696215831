import axios from 'axios'
import { call, put, takeLatest } from 'redux-saga/effects'
import Cache from 'ion-cache'

export const FETCH_SUBSCRIBEBLOCK = 'api/FETCH_SUBSCRIBEBLOCK'
export const FETCH_SUBSCRIBEBLOCK_SUCCESS = 'api/FETCH_SUBSCRIBEBLOCK_SUCCESS'
export const FETCH_SUBSCRIBEBLOCK_ERROR = 'api/FETCH_SUBSCRIBEBLOCK_ERROR'

const SERVER_URL = typeof window !== 'undefined' ? '/data/subscribe-block' : process.env.RAZZLE_SUBSCRIBE_BLOCK

const cache = new Cache()

export async function fetchSubscribeBlockAPI () {
  const result = await cache.get('subscribe-block')
  if (!result) {
    return axios.get(SERVER_URL)
      .then(response => cache.set('subscribe-block', response.data, 60 * 60000)) // Cache for 60 minutes
      .catch(err => {
        console.error(err)
        throw err
      })
  }
  return result
}

function * fetchSubscribeBlockSaga () {
  try {
    const res = yield call(fetchSubscribeBlockAPI)
    yield put({ type: FETCH_SUBSCRIBEBLOCK_SUCCESS, payload: res })
  } catch (e) {
    yield put({ type: FETCH_SUBSCRIBEBLOCK_ERROR, payload: e.message })
  }
}

export function * watchFetchSubscribeBlock () {
  yield takeLatest(FETCH_SUBSCRIBEBLOCK, fetchSubscribeBlockSaga)
}

// Saga actions
export const fetchSubscribeBlock = () => ({ type: FETCH_SUBSCRIBEBLOCK, isFetching: true, hasFetched: false })

export const initialState = {
  lastFetch: 0,
  didInvalidate: false,
  isFetching: false,
  hasFetched: false,
  hasError: false,
  canonical: null,
  error: null,
  subscribeBlock: null // do not mutate these
}

export const Reducer = (state = initialState, { type, payload, meta }) => {
  switch (type) {
    case FETCH_SUBSCRIBEBLOCK:
      return Object.assign({}, state, {
        didInvalidate: false,
        isFetching: true,
        hasFetched: false,
        hasError: false,
        canonical: null,
        error: null
      })
    case FETCH_SUBSCRIBEBLOCK_ERROR:
      return Object.assign({}, state, {
        hasError: true,
        error: payload,
        hasFetched: true,
        isFetching: false,
        didInvalidate: false,
        canonical: null
      })
    case FETCH_SUBSCRIBEBLOCK_SUCCESS:
      return Object.assign({}, state, {
        lastFetch: new Date(),
        hasFetched: true,
        isFetching: false,
        didInvalidate: false,
        subscribeBlock: payload,
        hasError: false,
        canonical: null,
        error: null
      })
    default:
      return state
  }
}
