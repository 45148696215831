import React, { Component } from 'react'
import { Route, Switch } from 'react-router-dom'
import Helmet from 'react-helmet'
import { withRouter, Redirect } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import loadable from '@loadable/component'
import { Sidebar, Segment, Menu } from 'semantic-ui-react'
import ServiceWorker from './ServiceWorker'
import ScrollToTop from 'ion-scrolltotop'
import { Mobile } from 'ion-media'
import { url2section } from 'ion-sections'
import CookieForm from './CookieForm'

import Header from './Shell/Header'
import Footer from './Shell/Footer'
import MobileMenu from './Shell/MobileMenu'
import PushPrompt from './PushPrompt'

import FaviconSVG from '../static/favicon/favicon.svg'

import logo from '../static/general/house-and-garden.svg'

import { hideMenu, handleToggleMenu, toggleSearch, pageView } from '../../store/app'
import { fetchArticles, fetchMoreArticles } from '../../store/articles'
import '../static/general/normalize.min.css'

import 'semantic-ui-css/components/sidebar.min.css'
import 'semantic-ui-css/components/segment.min.css'
import 'semantic-ui-css/components/menu.min.css'
import '../styles/app.scss'
import NewsletterPage from '../pages/NewsletterPage'
import { ArticlePageSkeleton, DefaultSectionPageSkeleton } from './Skeletons'

export const HomePage = loadable(() => import('../pages/LandingPages/HomePage'), { fallback: <DefaultSectionPageSkeleton /> })
export const NotFound = loadable(() => import('../pages/NotFound'))
export const DefaultSection = loadable(() => import('./DefaultSection'), { fallback: <DefaultSectionPageSkeleton /> })
export const Article = loadable(() => import('../pages/Article'), { fallback: <ArticlePageSkeleton /> })
export const StaticPage = loadable(() => import('../pages/StaticPage'))
export const SearchPage = loadable(() => import('../pages/SearchPage'))
export const DesignDirectoryForm = loadable(() => import('../pages/DesignDirectoryForm'))
export const PushNotificationsManagementPage = loadable(() => import('../pages/PushNotificationManagementPage'))
export const AuthorsSectionPage = loadable(() => import('../pages/AuthorsSectionPage'))
export const BookmarksPage = loadable(() => import('../pages/BookmarksPage'))

export class App extends Component {
  constructor (props) {
    super(props)
    this.handleToggleMenu = this.handleToggleMenu.bind(this)
    this.handleHideMenu = this.handleHideMenu.bind(this)
    this.toggleSearch = this.toggleSearch.bind(this)
    this.state = {
      wingbannerLeftActive: false,
      wingbannerRightActive: false,
      hamburgerActive: false,
      useSmall: false
    }
    // this.props.pageView(this.props.location.pathname)
  }

  componentDidUpdate (prevProps) {
    // Re-fetch articles when the contentKey changes
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.props.pageView(this.props.location.pathname)
    }
  }

  toggleSearch () {
    this.props.toggleSearch()
  }

  componentDidMount () {
    window.addEventListener('scroll', this.handleScroll, true)
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    const useSmall = window.scrollY > (125 + (this.state.useSmall ? -25 : 0))
    if (useSmall !== this.state.useSmall) { this.setState({ useSmall }) }
  }

  handleToggleMenu () {
    this.props.handleToggleMenu()
  }

  handleHideMenu () {
    if (this.props.showMenu) {
      this.props.hideMenu()
    }
  }

  render () {
    return (
      <>
        <div className='app'>
          <Helmet
            defaultTitle={process.env.RAZZLE_SITE_TITLE}
          >
            <html lang='en' />
            <link rel='shortcut icon' href={FaviconSVG} />
            <link rel='mask-icon' href={FaviconSVG} color='#000000' />
            <meta name='description' content={process.env.RAZZLE_SITE_DESCRIPTION} />
            <meta name='msvalidate.01' content='5A0910AA1A12E32A5352933CF5FD96E9' />
            <meta property='fb:app_id' content='1915095138801045' />
            <meta property='og:type' content='article' />
            <meta charset='utf-8' />
            <meta property='og:title' content={process.env.RAZZLE_SITE_TITLE} />
            <meta property='og:description' content={process.env.RAZZLE_SITE_DESCRIPTION} />
            <meta property='og:image' content={logo} />
            <meta itemprop='inLanguage' content='en' />
            <meta itemprop='image' content={logo} />
            <meta name='viewport' content='width=device-width, initial-scale=1, viewport-fit=cover' />
            <meta name='mobile-web-app-capable' content='yes' />
            <meta name='theme-color' content='#ffffff' />
            <meta name='google-site-verification' content='ahJyp52x__PNoY1B7xLNzkfN_f0unOpvGpLW4AcYIBk' />
            <meta itemprop='image' content={logo} />
            <meta itemprop='thumbnailUrl' content={logo} />
            <meta name='twitter:card' content='summary_large_image' />
            <meta name='twitter:image:src' content={logo} />
            <meta name='facebook-domain-verification' content='iteewcq7tl3m07pm7a1fvjp9itp0td' />
            <link rel='canonical' itemprop='url' href={process.env.RAZZLE_SITE_URL + (this.props.location.pathname === '/' ? '' : this.props.location.pathname)} />
            <body className={(this.props.showMenu ? 'menu-open' : 'menu-closed') + ' dfp-take-over-ad'} />
          </Helmet>
          <Sidebar.Pushable as={Segment}>
            <Sidebar as={Menu} animation='push' width='wide' visible={this.props.showMenu} icon='labeled' vertical>
              <Mobile>
                <MobileMenu onToggleMenu={this.handleToggleMenu} />
              </Mobile>
            </Sidebar>
            <Sidebar.Pusher dimmed={this.props.showMenu} onClick={this.handleHideMenu}>
              <Header useSmall={this.state.useSmall} onToggleMenu={this.handleToggleMenu} toggleSearch={this.toggleSearch} {...this.props} />
              <main id='main-content' role='main' className='content'>
                <ScrollToTop>
                  <Switch>
                    <Route exact path='/' render={p => <HomePage {...this.props} />} />
                    <Route exact path='/shell' render={() => <div>Loading...</div>} />
                    <Route exact path='/newsletter/thankyou' render={() => <NewsletterPage hasSubmitted />} />
                    <Route exact path='/newsletter' component={NewsletterPage} />
                    <Route
                      exact path='/:page(design-directory)' render={props =>
                        <StaticPage
                          page={props.match.params.page}
                          title='Design Directory: SA’s Leading Design & Decor Guide | House & Garden'
                          description='We invite you to list your company in South Africa’s leading design and decor guide. Click here for more information'
                          {...this.props}
                        />}
                    />
                    <Route
                      exact path='/my-notifications' render={() => (
                        <PushNotificationsManagementPage
                          {...this.props}
                          section='news'
                          mostReadSection='news'
                          title='Notifications Management'
                          description='View your notifications history and manage the entities that you follow.'
                        />
                      )}
                    />
                    <Route
                      path='/authors' exact
                      render={props =>
                        <AuthorsSectionPage
                          section='authors'
                          title='Authors Page'
                          description='Authors Page'
                          {...this.props}
                        />}
                    />
                    <Route
                      exact path='/my-bookmarks' render={() => (
                        <BookmarksPage
                          {...this.props}
                          section='news'
                          mostReadSection='news'
                          title='Bookmarks'
                          description='Articles which the user has bookmarked for offline reading.'
                        />
                      )}
                    />
                    <Route
                      exact path='/:page(subscriptions)' render={props =>
                        <StaticPage
                          page={props.match.params.page}
                          title='Subscribe to the SA’s Finest Decor Magazine | House & Garden'
                          description='Get South Africa’s Finest Decor Magazine delivered to your doorstep - the most convenient way for you to receive your favourite magazine'
                          {...this.props}
                        />}
                    />
                    <Route
                      exact path='/:page(privacy-policy)' render={props =>
                        <StaticPage
                          page={props.match.params.page}
                          title='Privacy Policy | House & Garden'
                          description='Click here to read our privacy policy'
                          {...this.props}
                        />}
                    />
                    <Route
                      exact path='/:page(contact-us)' render={props =>
                        <StaticPage
                          page={props.match.params.page}
                          title='Contact Us | House & Garden'
                          description='Got a question, comment or request? Simply fill in the fields below and write away. Your email will be answered by the Glamour online team and we’ll be in touch as soon as possible. Don’t forget to sign up for the e-newsletter to be the first to hear about events and promotions at House & Garden'
                          {...this.props}
                        />}
                    />
                    <Route
                      exact path='/:page(masthead)' render={props =>
                        <StaticPage
                          page={props.match.params.page}
                          title='Contact Us for More Information | House & Garden'
                          description='We would love to hear from you! Click here to contact us and we will respond as soon as possible'
                          {...this.props}
                        />}
                    />
                    <Route
                      exact path='/:page(terms-and-conditions)' render={props =>
                        <StaticPage
                          page={props.match.params.page}
                          title='Terms and Conditions | House & Garden'
                          description='Read our terms and conditions for more information'
                          {...this.props}
                        />}
                    />
                    <Route
                      exact path='/:page(advertise-with-us)' render={props =>
                        <StaticPage
                          page={props.match.params.page}
                          title='Our Latest Print & Digital Rates | House & Garden'
                          description='To advertise with House & Garden, please click here for more information'
                          {...this.props}
                        />}
                    />
                    <Route
                      exact path='/:page(help)' render={props =>
                        <StaticPage
                          page={props.match.params.page}
                          title='Help'
                          description='Help pages for navigating your way around the site.'
                          {...props}
                        />}
                    />
                    <Route
                      exact path='/:page(payment-success)' render={props =>
                        <StaticPage
                          page={props.match.params.page}
                          title='Payment Successful'
                          description='Payment Successful'
                          {...this.props}
                        />}
                    />
                    <Route
                      exact path='/:page(payment-error)' render={props =>
                        <StaticPage
                          page={props.match.params.page}
                          title='Payment Error'
                          description='Payment Error'
                          {...this.props}
                        />}
                    />
                    <Route
                      exact path='/:page(woolies-christmas)' render={props =>
                        <StaticPage
                          page={props.match.params.page}
                          title='Hope, Joy, Love & #WooliesChristmas | House & Garden'
                          description='Woolworths has thought of everthing you need to make your festive season a memorable one | House & Garden'
                          {...this.props}
                        />}
                    />
                    <Route
                      exact path='/:page(design-of-the-year)' render={props =>
                        <StaticPage
                          page={props.match.params.page}
                          title='Design of the Year | House & Garden'
                          description=''
                          {...this.props}
                        />}
                    />
                    <Route exact path='/design-directory-booking-form' render={props => <DesignDirectoryForm {...this.props} />} />
                    <Route exact path='/search' render={props => <SearchPage {...this.props} />} />

                    {/* Defaults and direct article access */}
                    <Route path='/preview' render={props => <Article {...this.props} {...props} url='preview' contentKey={999999} />} />
                    <Route path='/:contentKey([0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12})' render={props => <Article {...this.props} {...props} contentKey={props.match.params.contentKey} />} />
                    <Route path='/:contentKey([0-9]{5,})' render={props => <Article {...this.props} {...props} contentKey={props.match.params.contentKey} />} />
                    <Route path='/:section([a-zA-Z0-9-]+)/' strict exact render={props => <Redirect to={'/' + props.match.params.section} />} />
                    <Route path='/:section([a-zA-Z0-9-]+)' render={props => <DefaultSection {...this.props} {...props} twitterName='HouseGardenSA' />} />
                    {/* <Route exact path='/404' component={NotFound} {...this.props} /> */}
                    <Route path='*' component={NotFound} {...this.props} />
                  </Switch>
                </ScrollToTop>
              </main>
              <CookieForm />
              <PushPrompt />
              <Footer section={url2section(this.props.location.pathname)} />
            </Sidebar.Pusher>
          </Sidebar.Pushable>
          <ServiceWorker />
        </div>
      </>
    )
  }
}

const mapStateToProps = (state) => (state.app)
const mapDispatchToProps = (dispatch) => bindActionCreators({ hideMenu, handleToggleMenu, toggleSearch, pageView, fetchArticles, fetchMoreArticles }, dispatch)

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
